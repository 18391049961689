import sunset from '../images/sunset.png'
import React from 'react'
import EmailCapture from './EmailCapture'


export default function PumpkinBanner() {
    return (
    
      <div id="pumpkinbanner" className="">
        <div className="mx-auto max-w-7xl  px-6 py-24 sm:py-20 lg:px-8">
          <h1 className=" mb-8 text-5xl font-medium italic text-yellow tracking-tight text-white lg:text-left text-center">
          <span class='font-bold'>2024</span> <span class="font-bold text-white hover:text-[#111111]" id="friz">PUMPKIN PATCH </span><br></br><span class="text-2xl lg:text-4xl tracking-wide font-light">Dates Announced: </span>
          <span id="firered" class="rounded-full  mx-2 lg:text-xl font-semibold text-sm tracking-normal  text-white lg:py-3.5  py-2 px-6 bg-red mt-20 text-nowrap">Sept 28th - Oct 31st</span>
          </h1>
          <p class="lg:text-2xl text-lg text-md text-white font-medium lg:text-left text-center"><span class='text-yellow'>Bigfoot! Corn Maze</span> - <span class='text-yellow'>Tractor Train Hayrides </span> - <span class='text-yellow'>Pumpkins</span> - <span class='text-yellow'>Playground</span></p>

      
          <EmailCapture />

        </div>
      </div>
    )
  }
  