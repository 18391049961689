import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import sunset from '../images/sunset.png'
import PumpkinPage2 from './PumpkinPatch2'

const features = [
  {
    name: 'Push to deploy.',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'SSL certificates.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: LockClosedIcon,
  },
  {
    name: 'Database backups.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    icon: ServerIcon,
  },
]

const faqs = [
    {
      id: 1,
      question: "How long should we plan to spend at the farm to do all the activities?",
      answer:
        "1-2 hours depending",
    },
    {
        id: 2,
        question: "Is there free parking?",
        answer:
          "YES",
      },
      {
        id: 3,
        question: "Do I need tickets in advance?",
        answer:
          "NO, but you can save on ticket prices by buying online in advance!",
      },
      {
        id: 4,
        question: "Can we get pumpkins for carving?",
        answer:
          "YES! We have a huge selection of pumpkins of all sizes and perfect for carving. We also have beautiful selections of decorative gourds and winter squashes. We grow without pesticides and chemicals so many varieties are wonderful to eat or and perfect pumpkin seeds for roasting. ",
      },
      {
        id: 5,
        question: "What should we wear?",
        answer:
          "It's a farm, so be prepared for mud, especially on rainy days, and dress for the weather. ",
      },
      {
        id: 6,
        question: "Can we get food there?",
        answer:
          "Yes! There are multiple food options: food trucks, Indian food and fall carnival concessions. ",
      },
      {
        id: 7,
        question: "What's the latest time of entry?",
        answer:
          "5:30 pm but try to come earlier to enjoy more fun. ",
      },
    // More questions...
  ]

export default function PumpkinPage() {
  return (
    <>
    <div className="relative bg-[#010101] isolate darkness-50    overflow-hidden pt-14">
    <img
      alt=""
      src={sunset}
      className="absolute inset-0 opacity-50  -z-10 h-full w-full object-cover"
    />
    <div
      aria-hidden="true"
      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    >
      <div
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
        className="relative  aspect-[1155/678]  rotate-[30deg] bg-gradient-to-tr from-[#ed4811] to-[#ed4811] opacity-20 "
      />
    </div>
    <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
      <div className=" sm:mb-8 flex mb-5 justify-center">
        <div className="relative bg-[#ed4811] hover:bg-[#010101]  lg:text-xl rounded-full px-5 py-2  leading-6 text-white">
        September 28, 2024 - October 31, 2024
        
        </div>
      </div>
      <div className="text-center">
        <h1 id="friz" className="md:text-7xl text-5xl font-bold tracking-tight text-white sm:text-7xl">
          SIDHU FARMS <br></br>PUMPKIN PATCH
        </h1>
        <p className="mt-6 bg-[#ffa500] mc-4 rounded-lg lg:text-lg  leading-8 text-gray-300 p-3">
        The Sidhu Farms Pumpkin Patch & 5-acre Bigfoot themed Corn Maze is open September 28, 2024 - October 31, 2024 and is by far, the most fun pumpkin patch in the Northwest! 
        </p>
    
      </div>
    </div>
    <div
      aria-hidden="true"
      className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    >
      <div
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
      />
    </div>
  </div>
  <PumpkinPage2 />


</>
      
  )
}
