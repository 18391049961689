import { useState } from 'react'
import {
  ArrowPathIcon,
  ChevronRightIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'


import Hero from './Hero'
import Feature1 from './Feature1'
import AboutFeature1 from './TimelineFeature.js'

import ProductFeature1 from './ProductSnippet.js'
import PhotoGal from './PhotoGal'
import timeline from '../images/Timelinev3.png'
import { createRenderer } from 'react-dom/test-utils'
import b from '../images/b.PNG'
import Natural from './Natural.js'
import PumpkinBanner from './PumpkinBanner.js'
import ThinBanner from './ThinBanner.js'


const navigation = [
  { name: 'Home', href: '/Home' },
  { name: 'The Sidhu Story', href: '/the-sidhu-story' },
  { name: 'Visit the Farm', href: '/visit-the-farm' },
  { name: 'Store', href: '/store' },
  { name: 'Contact us', href: '/contact-us' }

]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Home() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div>

    <div id="shipping" class='py-3 bg-[#ffa500] w-full mt-16'>
          <p  class=" pt-3 font-medium text-lg text-center text-gray-900 "> <span class="font-bold text-2xl text-blue hover:text-[#111111]" id="friz">2024 SIDHU FARMS PUMPKIN PATCH  </span><br></br>Dates Announced: <span class="font-bold">Sept 28th - Oct 31st</span></p>
    </div>

      <Hero />
      <div className=" bg-white">

      <main>
      <PumpkinBanner />

        <div class="bg-blue">
        <section className="container mx-auto px-4 ">
        <img class='lg:h-60 pt-10 justify-center m-auto' src={b} />

        <h2 className="text-3xl mb-6 text-center text-white">Welcome to Sidhu Farms</h2>

        <p className="text-center text-white max-w-4xl mx-auto mb-6">
        The Sidhu family has been farming in Puyallup since 1997. Known for growing organic blueberries, strawberries, raspberries, blackberries and specialty berries, the 120 acre farm is owned by multigenerational farmer Chet Sidhu, run by his three sons, their wives and extended family. Committed to farming in the most natural way possible, without chemical pesticides or synthetic fertilizers, Sidhu Farms truly grows nature’s very best tasting berries. 
        </p>

        <p className="text-center text-white max-w-4xl mx-auto ">
        Sidhu Farms also produces honey, low sugar jams, and a variety of vegetable row crops.  The farmstand is open all year with a Pumpkin Patch & Corn Maze in the fall. Find Sidhu Farms at farmers markets year round. Order online for delivery. <br></br><br></br> WE are committed to farming in the most natural way possible & the results are way better tasting berries!
        </p>

        </section>
        {/* Farm Highlights Section */}
        <section className="container mx-auto px-4  mt-10 mb-8  text-center bg-gray-100">

          <div className="grid grid-cols-1 md:grid-cols-3 max-w-md md:max-w-4xl justify-center text-center m-auto gap-6 mb-20">

            <div className="text-center p-8  border-[#00003f] border-2 rounded-lg shadow-md">
              <h3 className="text-xl  font-semibold mb-2 text-yellow">60+ Varieties of <br></br>Specialty Berries</h3>
              <p className="text-white">are grown on our farm each season.</p>
            </div>
            <div className="text-center p-4 border-2 border-[#00003f] rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2 text-yellow">Certified Organic Blueberries</h3>
              <p className="text-white">no chemicals, no pesticides. no synthetic fertilizers. We farm everything the exact same way, certified or not!
              </p>
            </div>
            <div className="text-center p-4  border-2 border-[#00003f] rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2 text-yellow">Good Agricultural Practices (GAP) Certified</h3>
              <p className="text-white">ensuring safe handling & conditions in farming, processing and distributing.</p>
            </div>
      
          </div>
        </section>


        </div>
     

        <div class='bg-white' >

        <ProductFeature1 />
        <div id="shipping" class='py-2.5 bg-blue w-full mb-14'>
          <p  class="font-bold text-lg text-center text-white">Free Shipping on Orders above $100</p>
        </div>
        <Natural />

        <PhotoGal />
        <AboutFeature1 />


        </div>

        <div class=" lg:-mb-32  -mb-40">
        <iframe src='https://245e53f84de641d29da6869789d90b57.elf.site' width='100%' height='1000' frameborder='0'></iframe>

        </div>
        
      </main>

   
    </div>
    </div>
  )
}
