import React from "react";

const Product = ({ name, image, price }) => (
  <div className="flex flex-col items-center justify-center">
    <img src={image} alt={name} className="lg:w-26 w-auto lg:h-26 h-24 w-24 mb-2 rounded object-fit object-cover" />
    <h3 className="text-md font-semibold text-center ">{name}</h3>
    <p className="text-md font-semibold mb-10 p-1 px-4 mt-2 bg-pink text-white  rounded-full">{price}</p>

  </div>
);

const ProductsSnippet = () => {
  const products = [
    {
      name: "Frozen Blueberries",
      image: "https://131442173.cdn6.editmysite.com/uploads/1/3/1/4/131442173/s626929755964675106_p3_i3_w1053.jpeg?width=2400&optimize=medium",
      price: "$80.00",
    },
    {
      name: "Half Case Jam (6 Jars)",
      image: "https://131442173.cdn6.editmysite.com/uploads/1/3/1/4/131442173/s626929755964675106_p14_i1_w1900.png?dpr=2",
      price: "$50.00",
    },
    {
      name: "Frozen Raspberries",
      image: "https://131442173.cdn6.editmysite.com/uploads/1/3/1/4/131442173/s626929755964675106_p5_i2_w588.jpeg?width=2400&optimize=medium",
      price: "$95.00",
    },
    {
      name: "Fresh Honey",
      image: "https://131442173.cdn6.editmysite.com/uploads/1/3/1/4/131442173/s626929755964675106_p7_i2_w960.jpeg?width=2400&optimize=medium",
      price: "$16.00",
    },
  ];

  return (
    <div className="container mx-auto p-4 pt-14 ">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
          {products.map((product, index) => (
            <Product key={index} {...product} />
          ))}
        </div>
        <div className="flex flex-col justify-center items-center lg:text-left lg:mr-10 mr-0">
        <h1 id="" class="mb-5 text-4xl font-light text-blue">From the <span id="friz" class="font-bold text-blue">HARVEST</span></h1><dl class="text-pink border-solid border-2"></dl>
        <p className="text-gray-700 mb-4 text-center">
            Explore our selection of fresh berries and berry products in our
            online shop.
          </p>
          <button
          id='Poppins'
          type="button"
          class="rounded-full  bg-pink hover:bg-blue shadow-sm shadow-gray-dark  backdrop-blur mt-4  px-7 pb-[8px] mb-8 pt-[10px] hover: text-md font-semibold  uppercase tracking-wide text-white  transition duration-150 ease-in-out hover:border-neutral-100  hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 "
          data-twe-ripple-init
          data-twe-ripple-color="light"><a href="https://www.sidhufarm.org/" target="_blank">
          Visit our Shop</a>
        </button>
        </div>
      </div>
    </div>
  );
};

export default ProductsSnippet;
