import React, {useState, useEffect} from "react";
import { useForm, useWatch } from "react-hook-form";

export default function EmailCapture() {
    const [result, setResult] = React.useState("");
  
    const onSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "93a120e6-1d04-4dff-8f63-1c12f3e9f3d0");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Email was submitted successfully");
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };
  
    return (
      <div class="mt-6 lg:text-left text-center">
        <form onSubmit={onSubmit}>
            <h3 class="lg:text-xl text-lg mb-4 italic text-white font-semibold">Join our email list to be notified when presale tickets launch!</h3>
          <input class="" placeholder=" Name" type="text" name="name" required/>
          <input class="ml-3" placeholder=" Email Address" type="email" name="email" required/>
  
          <button class="ml-3 mt-4 lg:mt-0 bg-yellow px-4 rounded-md" type="submit">Submit</button>
  
        </form>
        <span class="text-white mt-6">{result}</span>
  
      </div>
    );
  }