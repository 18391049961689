import patch1 from "../images/patch1.jpeg"
import patch2 from "../images/patch2.jpeg"
import patch3 from "../images/patch3.jpeg"





const stats = [
    { label: 'Bigfoot! Corn Maze', value: '2021' },
    { label: 'Pumpkins! Pumpkins! Pumpkins! ', value: '37' },
    { label: 'Hay rides on Sidhu’s Tractor Train', value: '12' },
    { label: 'Kid’s Playground', value: '$25M' },
    { label: 'Food Trucks & Indian Food', value: '$25M' },
    { label: 'Farm Stand Grocery', value: '$25M' },
    { label: 'Concessions & Roasted Corn', value: '$25M' },
    { label: 'Kettle Corn & Hot Dogs', value: '$25M' },
    { label: 'Cool Photo Backdrops', value: '$25M' },
    { label: 'Giant Skeletons', value: '$25M' },
    { label: '2024 Bigfoot Pumpkin Patch T-Shirts!', value: '$25M' },


  ]

  const faqs = [
    {
      id: 1,
      question: "How long should we plan to spend at the farm to do all the activities?",
      answer:
        "1-2 hours depending",
    },
    {
        id: 2,
        question: "Is there free parking?",
        answer:
          "YES",
      },
      {
        id: 3,
        question: "Do I need tickets in advance?",
        answer:
          "NO, but you can save on ticket prices by buying online in advance!",
      },
      {
        id: 4,
        question: "Can we get pumpkins for carving?",
        answer:
          "YES! We have a huge selection of pumpkins of all sizes and perfect for carving. We also have beautiful selections of decorative gourds and winter squashes. We grow without pesticides and chemicals so many varieties are wonderful to eat or and perfect pumpkin seeds for roasting. ",
      },
      {
        id: 5,
        question: "What should we wear?",
        answer:
          "It's a farm, so be prepared for mud, especially on rainy days, and dress for the weather. ",
      },
      {
        id: 6,
        question: "Can we get food there?",
        answer:
          "Yes! There are multiple food options: food trucks, Indian food and fall carnival concessions. ",
      },
      {
        id: 7,
        question: "What's the latest time of entry?",
        answer:
          "5:30 pm but try to come earlier to enjoy more fun. ",
      },
    // More questions...
  ]

  
  export default function PumpkinPage2() {
    return (
      <div className="bg-[#101010]  py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
              <div className="relative overflow-hidden rounded-md  px-6 pb-32 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-32 xl:px-10 xl:pb-32 brightness-125 ">
                <img
                  alt=""
                  src={patch2}
                  className="absolute inset-0 h-auto w-full object-cover "
                />
             
               
              
              </div>
            </div>
            <div>
              <div className="text-base leading-7 text-[#ffa500] lg:max-w-lg">
                <p className="text-lg font-semibold leading-7 text-indigo-600">SIDHU FARMS PUMPKIN PATCH</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  <span id="friz" class="italic">BIGFOOT! </span>Themed Corn Maze
                </h1>

                <dl className="mt-10 grid grid-cols-1 text-white gap-2 border-[#ffa500] border-b border-gray-900/10 pb-6 sm:grid-cols-2">
                {stats.map((stat, statIdx) => (
                  <div key={statIdx}>
                                      <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.label}</dt>

                  </div>
                ))}
              </dl>
         
                
              </div>
             
            </div>

            
            
            <div className="max-w-xl text-lg text-[#ffa500]">

                
                  <p className="">
                  Sidhu Farms Pumpkin Patch is Super kid friendly with tons of activities for the littles, middles, big kids, and young-at-heart grown ups, that are ready for some seriously spooky fall fun!
                  </p>
                  <p className="mt-8">
                  We have a real pumpkin patch where we grow all of the pumpkins on our farm. In fact, we grow over 50 varieties of pumpkins, winter squashes and gourds for carving, decorating and even eating!! 

                  </p>
                  <p className="mt-8">
                  Enjoy family fun Hayrides on the signature Sidhu Farms style tractor train, giant festive halloween skeletons, kids playground, bouncy houses and games, delicious food options, and of course, a patch to pick your favorite halloween pumpkin! 
                  </p>
                  
                </div>
                <div class="  lg:max-w-lg lg:pb-8 " >
                    <img
                  alt=""
                  src={patch3}
                  className=" inset-0  rounded-md  h-full w-full object-cover brightness-125 "
                />
                </div>
            
          </div>
        </div>

   
    <div className="bg-[#ffa500]">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <h2 className="text-4xl font-semibold leading-10 tracking-tight justify-center text-center m-auto text-gray-900">Frequently asked questions</h2>
       
        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-lg font-semibold leading-7 text-gray-900">{faq.question}</dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>


      </div>
    )
  }
  