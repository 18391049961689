import logo from '../images/SFLogo.png'
import EmailBanner from './EmailBanner'


export default function Hero(){

return (

  

<div

  class="relative overflow-hidden  bg-cover bg-no-repeat p-12 text-center"
  id="bg" >

    
  <div
    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
    >
    <div class="flex h-full items-center justify-center ">
      <div class="text-white">
        <img id="" class='lg:h-[28em] h-[18em] mt-10 m-auto' src={logo} alt="Sidhu Logo" />
      <div className="items-center gap-x-4 mb-3 rounded-full px-4 py-1 text-md  leading-6 text-gray-600  ring-white hover:ring-gray-900/20">
                  <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
             
                </div>
        <h4 class="mb-6 text-2xl font-semibold"></h4>
        <button
          id='Poppins'
          type="button"
          class="rounded-full  bg-pink hover:text-blue hover:bg-yellow shadow-sm shadow-gray-dark  backdrop-blur mt-4  px-7 pb-[8px] pt-[10px] hover: text-md font-semibold  uppercase tracking-wide text-white  transition duration-150 ease-in-out hover:border-neutral-100  hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 "
          data-twe-ripple-init
          data-twe-ripple-color="light"><a href="https://www.sidhufarm.org/" target="_blank">
          Visit our Shop</a>
        </button>
      </div>
    </div>
  </div>
</div>

)
}